import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class MembersProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/members', query)
  }

  getPointHistories (query) {
    this.setHeader(getAuthToken())
    return this.get('/members/point-histories', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/members/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/members', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/members/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/members/${id}`)
  }
}

export default MembersProvider
