<template>
  <div class="items-container">
    <v-row>
      <v-col
        cols="12"
        md="4">
        <SearchBox
          v-model="query.search"
          :disabled="loading"
          @search="getItems(true)" />
      </v-col>
      <v-col
        cols="12"
        md="auto">
        <status-filters
          v-model="query.rank"
          label="ระดับลูกค้า"
          :items="ranks"
          capitalize
          :disabled="loading" />
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          outlined
          depressed
          block
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="createItem()">
          <span>
            เพิ่มลูกค้า
          </span>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
        <!-- <v-btn
          color="primary"
          outlined
          depressed
          block
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="createItem()">
          <span>
            นำออกข้อมูล
          </span>
          <v-icon>
            mdi-tray-arrow-up
          </v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          hide-default-footer>
          <template #[`item.name`]="{ item }">
            <span v-if="item.firstName || item.lastName">
              {{ `${item.firstName} ${item.lastName}` }}
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template #[`item.tel`]="{ item }">
            {{ item.tel || '-' }}
          </template>
          <template #[`item.totalOrders`]="{ item }">
            {{ item.totalOrders | showNumberFormat() }}
          </template>
          <template #[`item.rank`]="{ item }">
            <span class="text-capitalize">
              {{ item.rank }}
            </span>
          </template>
          <template #[`item.points`]="{ item }">
            {{ item.points | showNumberFormat() }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              left
              offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :ripple="false"
                  @click="detailItem(item)">
                  <v-list-item-title>
                    ดูข้อมูล
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :ripple="false"
                  @click="editItem(item)">
                  <v-list-item-title>
                    แก้ไข
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :ripple="false"
                  @click="confirmDialogToggle(item)">
                  <v-list-item-title>
                    ลบ
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-pagination
            v-model="query.page"
            :length="totalPages"
            :total-visible="7"
            circle
            color="primary" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import StatusFilters from '@/components/StatusFilters.vue'
import MembersProvider from '@/resources/members.provider'
import MemberRanksProvider from '@/resources/member-ranks.provider'

const MembersService = new MembersProvider()
const MemberRanksService = new MemberRanksProvider()

export default {
  components: {
    SearchBox,
    StatusFilters
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'เลขที่ลูกค้า',
        value: 'id',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'name',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'เบอร์โทรศัพท์',
        value: 'tel',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'ยอดการจองทัวร์',
        value: 'totalOrders',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'ระดับลูกค้า',
        value: 'rank',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'พอยท์',
        value: 'points',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    items: [],
    ranks: [],
    query: {
      page: 1,
      limit: 10,
      search: '',
      rank: null
    },
    totalPages: 1
  }),
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    },
    'query.rank': {
      handler () {
        this.getItems(true)
      },
      deep: true
    }
  },
  async mounted () {
    await this.getRanks()
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    changeRoute (to) {
      this.$router.push({ name: to })
    },
    async getRanks () {
      try {
        this.loading = true

        const { data } = await MemberRanksService.getItems({
          page: 1,
          limit: 9999,
          sortOrder: 'asc'
        })

        this.ranks = data.results.reduce((arr, r) => [
            ...arr,
            {
              text: r.name,
              value: r.name
            }
          ],
          [
            {
              text: 'ทั้งหมด',
              value: null
            }
          ])
      } catch (error) {
        console.error('getRanks', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await MembersService.getItems(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onRemove (id) {
      try {
        this.loading = true

        await MembersService.deleteItemById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'ลบข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onRemove', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    createItem () {
      this.$router.push({ name: 'MemberCreate' })
    },
    detailItem (item) {
      this.$router.push({
        name: 'MemberDetail',
        params: {
          id: item.id
        }
      })
    },
    editItem (item) {
      this.$router.push({
        name: 'MemberEdit',
        params: {
          id: item.id
        }
      })
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะลบ "${item.id}" ใช่หรือไม่?`,
        onConfirm: () => this.onRemove(item.id)
      })
    }
  }
}
</script>

<style scoped>
.items-container {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
